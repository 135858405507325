<template>
    <el-main>
        <el-tabs v-model="activeTabs">
            <el-tab-pane v-for="(item, index) in tabsList" :key="index" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
            <div v-if="activeTabs === 'one'">
                <el-form-item label="服务分类：" prop="classify_id">
                    <el-select size="small" v-model="ruleForm.classify_id" placeholder="请选择或搜索" clearable filterable>
                        <el-option v-for="item in serviceClassify" :key="item.id" :label="item.classify_name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-button type="text" @click="$router.push('/service/beautyServiceList')">没有分类,去添加分类</el-button>
                </el-form-item>
                <el-form-item label="排序：">
                    <el-input type="number" class="mr30" v-model="ruleForm.sort" placeholder="请输入内容"></el-input>
                    <span class="tips">数字越大，排名越靠前；如为空，排序默认为添加时间越近越靠前</span>
                </el-form-item>
                <el-form-item label="服务名称：" prop="service_name">
                    <el-input v-model="ruleForm.service_name" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="服务图片：" class="item" prop="service_picture">
                    <ReadyUploadSource @getSource="getSource" @changeOrder="list => (ruleForm.service_picture = list)"
                        @removeThis="index => ruleForm.service_picture.splice(index, 1)" :isMany="true"
                        :manyPath="ruleForm.service_picture" :isManyMax="10" :maxSize="1024 * 1024"></ReadyUploadSource>
                    <p class="tips">建议图片尺寸750*562px，大小不超过1M，最多可以上传10张图片，其中第一张为主图（有主图视频除外）</p>
                </el-form-item>
                <el-form-item label="服务主图：" class="item" prop="service_cover_picture">
                    <ReadyUploadSource @getSource="val => (ruleForm.service_cover_picture = val.path)"
                        :path="ruleForm.service_cover_picture" @removeThis="() => (ruleForm.service_cover_picture = '')">
                    </ReadyUploadSource>
                    <p>建议图片尺寸198px*144px，大小不超过1M，仅支持1张</p>
                </el-form-item>
                <el-form-item label="人工工时费用：" prop="one_hour_cost">
                    <el-input class="mr30" type="number" v-model="ruleForm.one_hour_cost" placeholder="请输入内容">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="非小区用户：" prop="not_community_deposit_cost">
                    <el-input class="mr30" type="number" v-model="ruleForm.not_community_deposit_cost" placeholder="请输入内容">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="小区非vip用户：" prop="community_not_vip_deposit_cost">
                    <el-input class="mr30" type="number" v-model="ruleForm.community_not_vip_deposit_cost"
                        placeholder="请输入内容">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="小区vip用户：" prop="community_vip_deposit_cost">
                    <el-input class="mr30" type="number" v-model="ruleForm.community_vip_deposit_cost" placeholder="请输入内容">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="上/下架：" prop="is_putaway">
                    <el-radio-group v-model="ruleForm.is_putaway">
                        <el-radio :label="-1">下架</el-radio>
                        <el-radio :label="1">上架</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div v-if="activeTabs === 'two'">
                <el-form-item label="服务属性：">
                    <el-switch @change="startUseSpec" v-model="ruleForm.is_open_service_attr" :inactive-value="0"
                        :active-value="1"></el-switch>
                </el-form-item>
                <div v-if="ruleForm.is_open_service_attr">
                    <el-form-item>
                        <div class="addSpecAllInfo">
                            <!-- 渲染规格列表-->
                            <div class="showSpecItem" v-if="ruleForm.service_attr.length">
                                <div v-for="(item, index) in ruleForm.service_attr" :key="index">
                                    <div class="inputBox">
                                        规格名称：
                                        <el-input placeholder="请输入规格名称" v-model="item.attr_key"></el-input>
                                    </div>
                                    <div class="specDetail">
                                        <div class="inputBox" v-for="(item1, index1) in item.attr_value" :key="index1">
                                            规格详情：
                                            <el-input placeholder="请输入规格详情" v-model="item.attr_value[index1]"></el-input>
                                            <i class="el-icon-error" v-if="item.attr_value.length > 1"
                                                @click="deleteSpecDetail(item, index1, 2)"></i>
                                        </div>
                                        <el-button type="text" @click="addSpecDetail(item)">+ 添加详情</el-button>
                                    </div>
                                    <i class="el-icon-error" v-if="ruleForm.service_attr.length > 1"
                                        @click="deleteSpecDetail(ruleForm.service_attr, index, 1)"></i>
                                </div>
                            </div>
                            <el-button @click="addSpecItem">+ 添加规格</el-button>
                        </div>
                    </el-form-item>
                </div>
            </div>

            <div v-if="activeTabs === 'three'"> 
                <el-form-item label="服务详情：">
                    <RichText :richTxt="ruleForm.service_details" @soninfo="val => (ruleForm.service_details = val)">
                    </RichText>
                </el-form-item>
            </div>
        </el-form>
        <Preservation @preservation="preservation"></Preservation>
    </el-main>
</template>
  
<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
import _ from 'lodash';
import RichText from '@/components/richText';
export default {
    data () {
        function checkSpic (rule, value, callback) {
            if (!value.length) {
                callback(new Error('请上传服务图片'));
            } else {
                callback();
            }
        }
        return {
            update_id: null,
            update_info: {},
            noHaveAddReduce: true,
            activeTabs: 'one', 
            tabsList: [
                { name: '基本信息', value: 'one' },
                { name: '服务属性', value: 'two' },
                { name: '服务详情', value: 'three' }
            ],
            ruleForm: {
                classify_id: '',
                service_name: '',
                service_picture: [],
                service_cover_picture: '',
                service_video: '',
                one_hour_cost: '',
                not_community_deposit_cost: '',
                community_not_vip_deposit_cost: '',
                community_vip_deposit_cost: '',
                is_open_service_attr: 0,
                service_attr: [],
                service_details: '',
                is_putaway: 1,
                sort: ''
            },
            rules: {
                classify_id: { required: true, message: '请选择服务分类', trigger: 'change' },
                service_name: { required: true, message: '请填写服务名称', trigger: 'blur' },
                service_picture: { required: true, validator: checkSpic, trigger: 'change' },
                one_hour_cost: { required: true, message: '请填写人工工时费用', trigger: 'blur' },
                not_community_deposit_cost: { required: true, message: '请填写非小区用户', trigger: 'blur' },
                community_not_vip_deposit_cost: { required: true, message: '请填写小区非VIP用户', trigger: 'blur' },
                community_vip_deposit_cost: { required: true, message: '请填写小区VIP用户', trigger: 'blur' },
                is_putaway: { required: true, message: '请选择是否上架', trigger: 'change' },
                service_details: { required: true, message: '请填写服务详情', trigger: 'blur' },
                service_cover_picture: { required: true, message: '请上传服务主图', trigger: 'blur' },
            },
            serviceClassify: [],

        };
    },
    components: {
        Preservation,
        ReadyUploadSource,
        RichText,
    },
    created () {
        this.getServiceClassify();
        if (this.$route.query.id) {
            this.update_id = this.$route.query.id;
            this.getUpdateServices();
        }
    },
    methods: {
        getFile () {
            let files = this.$refs.inputer.files[0];
            let formData = new FormData();
            formData.append('files', files);
            console.log(777, formData);
            this.$axios.uploadFile(this.$api.user.addFiles, formData).then(res => {
                if (res.code == 0) {
                    this.ruleForm.service_document = res.result.path;
                    console.log(8888, res.result.path);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 获取要修改的服务信息
        getUpdateServices () {
            this.$axios
                .post(this.$api.repair.service.info, {
                    id: this.update_id,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.update_info = res.result;
                        let obj = _.cloneDeep(res.result);
                        obj.service_picture = JSON.parse(res.result.service_picture);
                        obj.service_attr = res.result.service_attr ? JSON.parse(res.result.service_attr) : []
                        this.ruleForm = obj;
                    }
                });
        },
        // 点击确认保存按钮
        preservation () {
            let checkRes = this.checkDataLegitimacy();
            if (checkRes === false) return;
            let url = !this.update_id ? this.$api.repair.service.add : this.$api.repair.service.edit;
            let obj = _.cloneDeep(this.ruleForm);
            if (this.update_id) {
                obj.id = Number(this.update_id);
            }
            obj.classify_id = this.ruleForm.classify_id
            if (!obj.is_open_service_attr) {
                delete obj.service_attr
            }
            // 整理无用数据
            for (const key in obj) {
                if (obj[key] === '') {
                    delete obj[key];
                }
            }
            this.$axios.post(url, obj).then(res => {
                if (res.code === 0) {
                    let str = this.update_id ? '编辑成功' : '添加成功';
                    this.$message.success(str);
                    this.$store.state.utils.is_update = 0;
                    this.$router.push('/repair/service/list');
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 调用接口时检测数据是否合法
        checkDataLegitimacy () {
            if (!this.ruleForm.classify_id) {
                this.$message.warning('请选择服务分类');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.ruleForm.service_name) {
                this.$message.warning('请填写服务名称');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.ruleForm.service_picture.length) {
                this.$message.warning('请上传服务图片');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.ruleForm.one_hour_cost) {
                this.$message.warning('请填写工时费用');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.ruleForm.community_not_vip_deposit_cost) {
                this.$message.warning('请填写非小区用户');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.ruleForm.community_vip_deposit_cost) {
                this.$message.warning('请填写小区VIP用户');
                this.activeTabs = 'one';
                return false;
            }
            if (this.ruleForm.is_open_service_attr) {
                if (!this.ruleForm.service_attr.every(v => v.attr_key && v.attr_value.every(w => w))) {
                    this.$message.warning('请将服务属性填写完整');
                    this.activeTabs = 'two';
                    return false;
                }
            }
            if (!this.ruleForm.service_details) {
                this.$message.warning('请填写服务详情！！');
                this.activeTabs = 'three';
                return false;
            }
        },
        // 获取组件中选中的图片
        getSource (list) {
            list.map(item => {
                this.ruleForm.service_picture.push(item.path);
            });
        },
        // 获取服务分类
        getServiceClassify () {
            this.$axios.post(this.$api.repair.service.ClassifyList, {
                page: 1,
                rows: 1000,
                classify_name: '',
                is_show: 1
            }).then(res => {
                if (res.code === 0) {
                    this.serviceClassify = res.result.list;
                }
            });
        },
        // 开始使用规格
        startUseSpec () {
            if (this.ruleForm.is_open_service_attr && !this.ruleForm.spec.length) {
                let obj = {
                    spec_name: '',
                    spec_value: [{ spec_value: '' }, { spec_value: '' }],
                };
                this.ruleForm.spec.push(obj);
            }
        },
        // 添加一种规格
        addSpecItem () {
            this.ruleForm.service_attr.push({
                attr_key: '',
                attr_value: ['']
            });
        },
        // 添加一种规格详情
        addSpecDetail (data) {
            data.attr_value.push('');
        },
        deleteSpecDetail (data, index, status) {
            if (status === 1) data.splice(index, 1);
            if (status === 2) data.attr_value.splice(index, 1);
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background-color: #fff;
    padding-bottom: 80px;

    .upload {
        .el-button {
            position: relative;

            input {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
            }
        }
    }

    .partTitle {
        font-size: 14px;
        color: #000;
        font-weight: bold;
        line-height: 35px;
        border-bottom: 1px solid #c4c4cc;
    }

    .mb10 {
        margin-bottom: 10px;
    }

    .mr30 {
        margin-right: 30px;
    }

    .el-select,
    .el-input,
    .el-cascader {
        width: 400px;
        flex-shrink: 0;
    }

    .tips {
        font-size: 14px;
        color: #999;
        white-space: nowrap;
    }

    .addSpecAllInfo {

        .el-input {
            width: 300px;
        }

        .showSpecItem {
            margin-bottom: 20px;

            &>div {
                padding: 20px;
                border: 1px solid #ccc;
                position: relative;

                &>i {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    font-size: 24px;
                }
            }

            &>div:not(:first-child) {
                border-top: none;
            }

            .specDetail {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;

                &>div {
                    margin-right: 20px;
                    margin-bottom: 10px;
                }

                &>.el-button {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .inputBox {
        position: relative;
        display: flex;
        align-items: center;

        i {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 24px;
            transform: translate(50%, -50%);
        }
    }
}

table {
    border-collapse: collapse;
    margin: 20px 0;

    thead {
        th {
            padding: 10px;
            border: 1px solid #dcdfe6;
            text-align: center;
        }
    }

    tbody {
        td {
            text-align: center;
            padding: 0 10px;
            border: 1px solid #dcdfe6;
        }
    }
}
</style>
  